import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Jump Squats 12-12-12`}</p>
    <p>{`DB Stiff Leg Deadlifts 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`10-Pistols/leg`}</p>
    <p>{`20-Burpees`}</p>
    <p>{`8-Pistols/leg`}</p>
    <p>{`16-Burpees`}</p>
    <p>{`6-Pistols/leg`}</p>
    <p>{`12-Burpees`}</p>
    <p>{`4-Pistols/leg`}</p>
    <p>{`8-Burpees`}</p>
    <p>{`2-Pistols/leg`}</p>
    <p>{`4-Burpees`}</p>
    <p>{`1-Pistol/leg`}</p>
    <p>{`2-Burpees`}</p>
    <p>{`for time.`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`100ft sled pull (sled +135/90)`}</p>
    <p>{`15-Jump Squats`}</p>
    <p><strong parentName="p">{`*`}{`Holiday Schedule`}{`*`}</strong></p>
    <p><em parentName="p">{`-Sunday, Dec 24th: Free 12:30 class!`}</em></p>
    <p><em parentName="p">{`-Monday, Dec 25th: Closed for Christmas Day`}</em></p>
    <p><em parentName="p">{`-Tuesday, Dec 26th: 12:00 & 4:30pm classes only.  Open gym between
1-4:30.  The gym will close after the 4:30 class.`}</em></p>
    <p><em parentName="p">{`-Sunday, Dec 31st: 12:30 class`}</em></p>
    <p><em parentName="p">{`-Monday, January 1st: Free New Years Day class at 10:30!  No open gym,
all other classes are cancelled.`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get ready for the 3rd Annual Pre Open at The Ville!  Starting the
first week of January.  RX, Scaled, and Masters over 50 Divisions.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      